<template>
  <div>
    <v-pagination
      v-model="page"
      :pages="pages"
      :range-size="1"
      :hide-first-button="true"
      :hide-last-button="true"
      @update:modelValue="pagFunction"
    />
  </div>
</template>

<script>
import VPagination from '@hennge/vue3-pagination'
export default {
  name: 'VPaginationPlugin',
  components: {
    VPagination,
  },
  props: {
    pages: {
      type: Number,
      require: true,
      default: 1,
    },
    currentPage: {
      type: Number,
      require: true,
      default: 1,
    },
  },
  emits: ['pagFunction'],
  data() {
    return {
      page: 1,
    }
  },
  watch: {
    currentPage() {
      if (this.currentPage === 1) {
        this.page = 1
      }
    },
  },
  mounted() {
    this.page = this.currentPage
  },
  methods: {
    pagFunction() {
      this.$emit('pagFunction', this.page)
    },
  },
}
</script>
<style lang="scss">
.Pagination {
  display: flex;
  align-items: center;
  li {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  button {
    width: 100%;
    height: 100%;
    border: none;
    background: none;
    border-radius: 4px;
    cursor: pointer;
    color: #343d5d;
    font-weight: 500;
    font-size: 12px;
    &:hover {
      background: #e6e7eb !important;
    }
    &.Page-active {
      background: #e6e7eb !important;
    }
  }
  .DotsHolder {
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 5px;
    justify-content: center;
    svg {
      width: 10px;
      height: 10px;
    }
  }
  .PaginationControl {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 20px;
      height: 24px;
    }
  }
}
</style>
