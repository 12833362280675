<template>
  <section class="VSectionHistory section-profile">
    <h2 class="section-profile__title">
      Список операций
      <span v-if="GET_TRANSACTIONS">{{ GET_TRANSACTIONS.totalAmount }}</span>
    </h2>
    <div class="VSectionHistory__block">
      <vHostoryBlockHeader
        :watch-period="watchPeriod"
        :period-value="periodValue"
        :filter-date-start="filterDateStart"
        :filter-date-end="filterDateEnd"
        @onFilterDateStart="onFilterDateStart"
        @onFilterDateEnd="onFilterDateEnd"
        @onPeriodValue="onPeriodValue"
        @selectFilter="selectFilter"
        @onWatchPeriod="onWatchPeriod"
        @resetValues="resetValues"
      />
      <VHistoryTotalsPeriod
        v-if="watchPeriod || filter !== ''"
        :period-value="periodValue"
        :calculating-total-amount="
          GET_TRANSACTIONS?.calculatingTotalAmount
            ? GET_TRANSACTIONS.calculatingTotalAmount
            : 0
        "
        :calculating-final-tips="
          GET_TRANSACTIONS?.calculatingFinalTips
            ? GET_TRANSACTIONS.calculatingFinalTips
            : 0
        "
        :filter-date-start="filterDateStart"
        :filter-date-end="filterDateEnd"
      />
      <vHistoryList
        v-if="GET_TRANSACTIONS && GET_TRANSACTIONS?.elements.length"
        :items="GET_TRANSACTIONS.elements"
        :watch-period="watchPeriod"
        :filter="filter"
        @toggleConfirmModal="toggleConfirmModal"
      />
      <div v-else>
        За выбранный период транзакций нет
      </div>
    </div>
    <vPaginationPlugin
      v-if="
        GET_TRANSACTIONS &&
          GET_TRANSACTIONS.last_page &&
          GET_TRANSACTIONS.last_page > 1
      "
      :pages="GET_TRANSACTIONS.last_page"
      :current-page="page"
      @pagFunction="pagFunction"
    />
    <vHistoryModal
      v-show="confirmModal"
      @toggleConfirmModal="toggleConfirmModal"
      @reverseTransaction="reverseTransaction"
    />
    <vLightPreloader v-if="preloader" />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vPaginationPlugin from '@/components/v-pagination-plugin'
import vHostoryBlockHeader from '@/components/history/v-history-block-header'
import VHistoryTotalsPeriod from '@/components/history/v-history-totals-period'
import vHistoryList from '@/components/history/v-history-list'
import vHistoryModal from '@/components/history/v-history-modal'
import vLightPreloader from '@/components/v-light-preloader'
import { filterWeak, filterMonth, filterYear } from '@/utils/filterDate'
export default {
  name: 'VSectionHistory',
  components: {
    vHostoryBlockHeader,
    vHistoryList,
    vPaginationPlugin,
    vHistoryModal,
    vLightPreloader,
    VHistoryTotalsPeriod,
  },
  data() {
    return {
      confirmModal: false,
      preloader: false,
      filter: '',
      page: 1,
      idTransaction: null,
      watchPeriod: false,
      periodValue: '',
      filterDateStart: '',
      filterDateEnd: '',
      defaultDate: null,
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
    ...mapGetters('transactions', ['GET_TRANSACTIONS']),
  },
  async mounted() {
    const weakData = filterWeak()
    await this.FETCH_TRANSACTIONS({
      token: this.GET_AUTHENTIFICATED,
      date_start: weakData.startDate,
      date_end: weakData.endDate,
      count: '',
      page: '',
    })
    this.defaultDate = {
      date_start: weakData.startDate,
      date_end: weakData.endDate,
    }
    setTimeout(() => {
      this.TOGGLE_PRELOADER(false)
    }, 500)
  },
  methods: {
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    ...mapActions('transactions', [
      'FETCH_TRANSACTIONS',
      'REVERSE_TRANSACTIONS',
      // 'CLEAR_TRANSACTIONS',
    ]),
    ...mapActions('user', ['GET_USER_DATA']),
    toggleConfirmModal(id) {
      this.idTransaction = id
      this.confirmModal = !this.confirmModal
    },
    async reverseTransaction() {
      this.preloader = true
      this.confirmModal = false
      await this.REVERSE_TRANSACTIONS({
        token: this.GET_AUTHENTIFICATED,
        id: this.idTransaction,
      })

      let filterData = null
      switch (this.filter) {
        case 'weak':
          filterData = filterWeak()
          break
        case 'month':
          filterData = filterMonth()
          break
        case 'year':
          filterData = filterYear()
          break
        default:
          filterData = {
            startDate: this.filter.date_start,
            endDate: this.filter.date_end,
          }
          break
      }

      await this.FETCH_TRANSACTIONS({
        token: this.GET_AUTHENTIFICATED,
        date_start: filterData.startDate,
        date_end: filterData.endDate,
        count: '',
        page: this.page,
      })
      this.preloader = false
    },
    selectFilter(val) {
      this.filter = val
      this.page = 1
    },
    async pagFunction(num) {
      console.log('test-num', num)
      this.page = num
      console.log('this.page', this.page)
      this.preloader = true
      console.log('filterData', filterData)
      let filterData = null
      console.log('filterData-2', filterData)

      switch (this.filter) {
        case 'weak':
          filterData = filterWeak()
          break
        case 'month':
          filterData = filterMonth()
          break
        // case 'year':
        //   filterData = filterYear()
        //   break
        default:
          filterData = {
            startDate: this.filter.date_start
              ? this.filter.date_start
              : this.defaultDate.date_start,
            endDate: this.filter.date_end
              ? this.filter.date_end
              : this.defaultDate.date_end,
          }
          break
      }

      await this.FETCH_TRANSACTIONS({
        token: this.GET_AUTHENTIFICATED,
        date_start: filterData.startDate,
        date_end: filterData.endDate,
        count: '',
        page: num,
      })
      console.log('filterData.startDate', filterData.startDate)
      console.log('filterData.endDate', filterData.endDate)
      this.preloader = false
    },
    onWatchPeriod(value) {
      this.watchPeriod = value
    },
    onPeriodValue(value) {
      this.periodValue = value
    },
    onFilterDateStart(value) {
      console.log('onFilterDateStart', this.filterDateStart)
      this.filterDateStart = value
      this.filterDateStart = this.filterDateStart.split('-')
      this.filterDateStart =
        this.filterDateStart[2] +
        '.' +
        this.filterDateStart[1] +
        '.' +
        this.filterDateStart[0]
    },
    onFilterDateEnd(value) {
      console.log('onFilterDateEnd', this.filterDateEnd)
      this.filterDateEnd = value
      this.filterDateEnd = this.filterDateEnd.split('-')
      this.filterDateEnd =
        this.filterDateEnd[2] +
        '.' +
        this.filterDateEnd[1] +
        '.' +
        this.filterDateEnd[0]
    },

    resetValues() {
      this.preloader = true
      this.filter = ''
      this.periodValue = ''
      this.filterDateStart = ''
      this.filterDateEnd = ''
      this.watchPeriod = false
      this.page = ''

      // const weakData = filterWeak()
      // await this.FETCH_TRANSACTIONS({
      //   token: this.GET_AUTHENTIFICATED,
      //   date_start: weakData.startDate,
      //   date_end: weakData.endDate,
      //   count: '',
      //   page: '',
      // })
      // this.CLEAR_TRANSACTIONS()
      this.preloader = false
      // console.log('resetValues', this.period)
    },
  },
}
</script>
<style lang="scss">
.VSectionHistory {
  &__block {
    padding: 24px;
    background: #fff;
    border: 1px solid #e6e7eb;
    border-radius: 11px;
    width: 100%;
    max-width: 1200px;

    @media (max-width: 769px) {
      padding: 20px 12px;
    }
  }

  .section-profile__title {
    @media (max-width: 769px) {
      display: none;
    }
  }
}
</style>
