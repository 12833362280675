<template>
  <div class="vHistoryBlockHeader">
    <div
      class="vHistoryBlockHeader__switcher"
      @click="useFilter('weak')"
    >
      <label
        for="day"
        class="vHistoryBlockHeader__switcher-label"
        :class="{ active: filter === 'weak' && period === null }"
      >
        Неделя
      </label>
    </div>
    <div
      class="vHistoryBlockHeader__switcher"
      @click="useFilter('month')"
    >
      <label
        for="month"
        class="vHistoryBlockHeader__switcher-label"
        :class="{ active: filter === 'month' && period === null }"
      >
        Месяц
      </label>
    </div>
    <!--    <div-->
    <!--      class="vHistoryBlockHeader__switcher"-->
    <!--      @click="useFilter('year')"-->
    <!--    >-->
    <!--      <label-->
    <!--        for="year"-->
    <!--        class="vHistoryBlockHeader__switcher-label"-->
    <!--        :class="{ active: filter === 'year' && period === null }"-->
    <!--      >-->
    <!--        Год-->
    <!--      </label>-->
    <!--    </div>-->
    <div class="vHistoryBlockHeader__period">
      <div
        class="vHistoryBlockHeader__period-label"
        :class="{ active: period }"
      >
        {{ period ? convertUTC : 'Выбрать период' }}
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 10L12 14L16 10"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <date-picker
        v-model:value="period"
        :lang="langString"
        :disabled-date="disabledAfterToday"
        range
        @closed="blur"
      />
    </div>

    <div
      v-if="filter !== '' || periodValue !== ''"
      class="vHistoryBlockHeader__period-reset"
    >
      <button @click="resetPeriod">
        Сбросить
      </button>
    </div>
    <div
      v-if="USER_DATA.role_user === 'support'"
      class="vHistoryBlockHeader__dropdown"
      @click.stop="toggleDropdown"
    >
      Статус
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 10L12 14L16 10"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <ul
        v-if="dropdown"
        class="vHistoryBlockHeader__dropdown-list"
        :class="{ disabled: filterLoading }"
      >
        <li
          v-for="(sortItem, idx) in sortList"
          :key="sortItem.id"
          class="vHistoryBlockHeader__dropdown-item"
          @click.stop="selectSort(idx, sortItem.active)"
        >
          <div
            class="vHistoryBlockHeader__dropdown-checkbox"
            :class="{ active: sortItem.active }"
          />
          {{ sortItem.label }}
        </li>
      </ul>
    </div>
    <vLightPreloader v-if="preloader" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import '@/utils/localeDatePicker'
import { filterWeak, filterMonth, filterYear } from '@/utils/filterDate'
import vLightPreloader from '@/components/v-light-preloader'
export default {
  name: 'VHistoryBlockHeader',
  components: {
    DatePicker,
    vLightPreloader,
  },
  props: {
    sortList: {
      type: Array,
      required: false,
      default: () => [],
    },
    filterLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    watchPeriod: {
      type: Boolean,
      required: true,
    },
    periodValue: {
      type: String,
      default: '',
    },
    filterDateStart: {
      type: String,
      default: '',
    },
    filterDateEnd: {
      type: String,
      default: '',
    },
  },
  emits: [
    'selectFilter',
    'filterTransaction',
    'onWatchPeriod',
    'onPeriodValue',
    'onFilterDateStart',
    'onFilterDateEnd',
    'resetValues',
  ],
  data() {
    return {
      period: null,
      langString: 'ru',
      filter: '',
      preloader: false,
      dropdown: false,
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED', 'USER_DATA']),
    convertUTC() {
      if (this.period) {
        let periodFrom = this.period[0].toLocaleString().split(',')
        periodFrom = periodFrom[0]

        let periodTo = this.period[1].toLocaleString().split(',')
        periodTo = periodTo[0]

        return periodFrom + ' - ' + periodTo
      }
      return ''
    },
  },
  watch: {
    async period() {
      if (this.period) {
        this.preloader = true

        this.$emit('onWatchPeriod', true)
        this.$emit('onPeriodValue', this.convertUTC)

        const startDate = new Date(this.period[0])
        const yearStart = startDate.getFullYear()
        const monthStart =
          parseInt(startDate.getMonth() + 1) < 10
            ? '0' + (startDate.getMonth() + 1)
            : startDate.getMonth() + 1
        const dayStart =
          parseInt(startDate.getDate()) < 10
            ? '0' + startDate.getDate()
            : startDate.getDate()
        const newStartDateArr = `${yearStart}-${monthStart}-${dayStart}`

        const endDate = new Date(this.period[1])
        const yearEnd = endDate.getFullYear()
        const monthEnd =
          parseInt(endDate.getMonth() + 1) < 10
            ? '0' + (endDate.getMonth() + 1)
            : endDate.getMonth() + 1
        const dayEnd =
          parseInt(endDate.getDate()) < 10
            ? '0' + endDate.getDate()
            : endDate.getDate()
        const newEndDateArr = `${yearEnd}-${monthEnd}-${dayEnd}`

        this.$emit('selectFilter', {
          date_start: newStartDateArr,
          date_end: newEndDateArr,
        })

        await this.FETCH_TRANSACTIONS({
          token: this.GET_AUTHENTIFICATED,
          date_start: newStartDateArr,
          date_end: newEndDateArr,
          count: '',
          page: 1,
        })
        this.preloader = false
      } else {
        this.$emit('onWatchPeriod', false)
        console.log('onWatchPeriod - false /', this.watchPeriod)
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.closeDropdown)
    document
      .querySelectorAll('.vHistoryBlockHeader__period input')
      .forEach((e) => (e.readOnly = true))
  },
  unmounted() {
    document.removeEventListener('click', this.closeDropdown)
  },
  methods: {
    ...mapActions('transactions', ['FETCH_TRANSACTIONS']),
    blur() {
      document.querySelector(':focus').blur()
    },
    disabledAfterToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return today < date
    },
    selectSort(idx, status) {
      const data = {
        idx,
        status,
      }
      // const filterArr = this.sortList.filter(i => i.active)
      this.$emit('filterTransaction', data)
    },
    toggleDropdown() {
      this.dropdown = !this.dropdown
    },
    closeDropdown() {
      console.log('closeDropdown')
      this.dropdown = false
    },
    async useFilter(val) {
      // this.resetPeriod()
      this.$emit('resetValues')
      this.preloader = true
      this.filter = val
      this.period = null
      this.$emit('selectFilter', val)
      let filterData = null

      switch (val) {
        case 'weak':
          filterData = filterWeak()
          break
        case 'month':
          filterData = filterMonth()
          break
        // case 'year':
        //   filterData = filterYear()
        //   break
        default:
          break
      }

      await this.FETCH_TRANSACTIONS({
        token: this.GET_AUTHENTIFICATED,
        date_start: filterData.startDate,
        date_end: filterData.endDate,
        count: '',
        page: 1,
      })
      this.$emit('onFilterDateStart', filterData.startDate)
      this.$emit('onFilterDateEnd', filterData.endDate)
      // console.log(
      //   'filterData.startDate - filterData.endDate /',
      //   filterData.startDate,
      //   filterData.endDate
      // )
      this.preloader = false
    },
    async resetPeriod() {
      if (this.periodValue !== '' || this.filter !== '') {
        this.preloader = true
        this.period = null
        this.filter = ''
        this.$emit('resetValues')

        const weakData = filterWeak()
        await this.FETCH_TRANSACTIONS({
          token: this.GET_AUTHENTIFICATED,
          date_start: weakData.startDate,
          date_end: weakData.endDate,
          count: '',
          page: '',
        })
        this.preloader = false
      } else {
        this.period = null
        this.filter = ''
        this.$emit('resetValues')
      }
    },
  },
}
</script>
<style lang="scss">
.vHistoryBlockHeader {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
  @media (max-width: 769px) {
    width: 300px;
  }
  @media (max-width: 370px) {
    flex-direction: column;
    align-items: flex-start;
  }
  &__switcher {
    display: flex;
    align-items: center;
    position: relative;
    @media (max-width: 370px) {
      margin-bottom: 16px;
    }
    &-label {
      cursor: pointer;
      padding: 6px 8px;
      font-size: 15px;
      border: 1px solid #e6e7eb;
      border-radius: 6px;
      margin-right: 12px;
      &.active {
        background: #6764ff;
        color: #fff;
        border-color: #6764ff;
      }
    }
  }
  &__period {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    @media (max-width: 769px) {
      margin-top: 16px;
    }
    @media (max-width: 370px) {
      margin-top: 0;
    }
    &-label {
      cursor: pointer;
      padding: 2px 8px;
      font-size: 15px;
      border: 1px solid #e6e7eb;
      border-radius: 6px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      &.active {
        background: #6764ff;
        color: #fff;
        border-color: #6764ff;
        svg {
          stroke: #fff;
        }
      }
    }
    svg {
      stroke: #80869a;
    }
  }
  &__period-reset {
    button {
      width: 115px;
      height: 30px;
      color: #ec4e4e;
      background: #fff6f6;
      border: 1px solid #ec4e4e;
      border-radius: 12px;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      cursor: pointer;
      @media (max-width: 769px) {
        margin-top: 16px;
      }
    }
  }
  &__dropdown {
    margin-top: 16px;
    cursor: pointer;
    padding: 2px 8px;
    font-size: 15px;
    border: 1px solid #e6e7eb;
    border-radius: 6px;
    position: relative;
    display: flex;
    align-items: center;
    &-list {
      position: absolute;
      left: 0;
      top: calc(100% + 2px);
      margin: 0;
      padding: 10px;
      border: 1px solid #e6e7eb;
      border-radius: 6px;
      background: #fff;
      &.disabled {
        & * {
          opacity: 0.4;
        }
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 999;
          cursor: default;
        }
      }
    }
    &-item {
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 5px 0;
    }
    &-checkbox {
      width: 14px;
      height: 14px;
      border: 1.5px solid #dcdee3;
      margin-right: 15px;
      border-radius: 4px;
      &.active {
        background-image: url(~@/assets/images/checkbox.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    svg {
      stroke: #80869a;
    }
  }
}
.mx-datepicker.mx-datepicker-range {
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  input {
    cursor: pointer;
  }
  .mx-icon-clear {
    display: none;
  }
  .mx-icon-calendar {
    display: none;
  }
}
.mx-calendar-range {
  .mx-calendar:last-child {
    display: none;
  }
}
.mx-datepicker-main.mx-datepicker-popup {
  border: 1px solid #e6e7eb;
  border-radius: 12px;
  width: 375px;
  box-shadow: none;
}
.mx-btn.mx-btn-text.mx-btn-current-year,
.mx-btn.mx-btn-text.mx-btn-icon-double-right,
.mx-btn.mx-btn-text.mx-btn-icon-double-left {
  display: none;
}
.mx-calendar.mx-calendar-panel-date {
  width: 100%;
}
.mx-calendar-header-label {
  button {
    color: #010d35;
    font-weight: 600;
    font-family: 'Commissioner';
    font-size: 20px;
  }
}
.mx-table th {
  color: #010d35;
  font-family: 'Commissioner';
  font-size: 15px;
  font-weight: 400;
}
.mx-calendar-content .cell {
  color: #010d35;
  font-family: 'Commissioner';
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
  width: 47px;
  height: 36px;
}
.mx-table-date .cell.not-current-month {
  color: #80869a;
  font-family: 'Commissioner';
  font-size: 16px;
  font-weight: 500;
}
.mx-calendar-content .cell:hover,
.mx-calendar-content .cell.active,
.mx-calendar-content .cell.in-range,
.mx-calendar-content .cell.hover-in-range {
  background: #f8f9fe;
  color: #6764ff;
}
.mx-calendar-content .cell.in-range,
.mx-calendar-content .cell.hover-in-range {
  border-radius: 0;
}
.mx-calendar-content .cell.active {
  border-radius: 12px 0 0 12px;
}
.cell.not-current-month.disabled:hover {
  background: none;
  color: #80869a;
}
.mx-calendar-content {
  height: auto;
}
.cell.in-range ~ .cell.active {
  border-radius: 0 12px 12px 0 !important;
}
.mx-calendar-content .cell.disabled {
  color: #ccc !important;
  background: none;
  opacity: 0.5;
}
.mx-calendar-content .cell.disabled:hover {
  color: #ccc;
}
</style>
