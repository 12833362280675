export const filterWeak = () => {
  const startDate = new Date()
  function dtime_nums(e) {
    let n = new Date()
    n.setDate(n.getDate() + e)
    return n.toLocaleDateString()
  }

  const endDate = dtime_nums(-7)

  const endDateArr = endDate.split('.')
  endDateArr.reverse()
  const newEndDateArr = endDateArr.join('-')

  const year = startDate.getFullYear()
  const month =
    parseInt(startDate.getMonth() + 1) < 10
      ? '0' + (startDate.getMonth() + 1)
      : startDate.getMonth() + 1
  const day =
    parseInt(startDate.getDate()) < 10
      ? '0' + startDate.getDate()
      : startDate.getDate()
  const newStartDateArr = `${year}-${month}-${day}`

  const dates = {
    startDate: newEndDateArr,
    endDate: newStartDateArr,
  }

  return dates
}

export const filterMonth = () => {
  const endDate = new Date()

  const year = endDate.getFullYear()
  const month =
    parseInt(endDate.getMonth() + 1) < 10
      ? '0' + (endDate.getMonth() + 1)
      : endDate.getMonth() + 1
  const day =
    parseInt(endDate.getDate()) < 10
      ? '0' + endDate.getDate()
      : endDate.getDate()
  const newEndDateArr = `${year}-${month}-${day}`

  const newStartDateArr = `${year}-${month}-01`

  const dates = {
    startDate: newStartDateArr,
    endDate: newEndDateArr,
  }

  return dates
}

export const filterYear = () => {
  const endDate = new Date()

  const year = endDate.getFullYear()
  const month =
    parseInt(endDate.getMonth() + 1) < 10
      ? '0' + (endDate.getMonth() + 1)
      : endDate.getMonth() + 1
  const day =
    parseInt(endDate.getDate()) < 10
      ? '0' + endDate.getDate()
      : endDate.getDate()
  const newEndDateArr = `${year}-${month}-${day}`

  const newStartDateArr = `${year}-01-01`

  const dates = {
    startDate: newStartDateArr,
    endDate: newEndDateArr,
  }

  return dates
}
