<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.57696 3.99988C4.22963 3.99988 3 4.9991 3 6.39414V9.68266C3 11.0777 4.22963 12.0769 5.57696 12.0769H7.38471V19C7.38471 19.2534 7.5126 19.4896 7.72474 19.6281C7.93688 19.7666 8.20459 19.7886 8.43651 19.6866L10.053 18.9761L11.6695 19.6866C11.8618 19.7712 12.0808 19.7712 12.2731 19.6866L13.8896 18.9761L15.5061 19.6866C15.7381 19.7886 16.0058 19.7666 16.2179 19.6281C16.43 19.4896 16.5579 19.2534 16.5579 19V12.0769H18.3657C19.713 12.0769 20.9426 11.0777 20.9426 9.68266V6.39414C20.9426 4.9991 19.713 3.99988 18.3657 3.99988H5.57696ZM16.5579 10.5769H18.3657C19.0371 10.5769 19.4426 10.1045 19.4426 9.68266V6.39414C19.4426 5.97227 19.0371 5.49988 18.3657 5.49988H5.57696C4.90549 5.49988 4.5 5.97227 4.5 6.39414V9.68266C4.5 10.1045 4.90549 10.5769 5.57696 10.5769H7.38471V8.03829C7.38471 7.62408 7.7205 7.28829 8.13471 7.28829H15.8079C16.2221 7.28829 16.5579 7.62408 16.5579 8.03829V10.5769ZM8.88471 17.8511V8.78829H15.0579V17.8511L14.1914 17.4702C13.9991 17.3857 13.7801 17.3857 13.5878 17.4702L11.9713 18.1808L10.3548 17.4702C10.1625 17.3857 9.94354 17.3857 9.75122 17.4702L8.88471 17.8511ZM10.4367 9.81793C10.0225 9.81793 9.68668 10.1537 9.68668 10.5679C9.68668 10.9821 10.0225 11.3179 10.4367 11.3179H13.506C13.9202 11.3179 14.256 10.9821 14.256 10.5679C14.256 10.1537 13.9202 9.81793 13.506 9.81793H10.4367ZM10.4367 12.3476C10.0225 12.3476 9.68668 12.6833 9.68668 13.0976C9.68668 13.5118 10.0225 13.8476 10.4367 13.8476H13.506C13.9202 13.8476 14.256 13.5118 14.256 13.0976C14.256 12.6833 13.9202 12.3476 13.506 12.3476H10.4367ZM10.4367 14.8772C10.0225 14.8772 9.68668 15.213 9.68668 15.6272C9.68668 16.0414 10.0225 16.3772 10.4367 16.3772H12.7386C13.1529 16.3772 13.4886 16.0414 13.4886 15.6272C13.4886 15.213 13.1529 14.8772 12.7386 14.8772H10.4367Z"
      fill="#80869A"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconTip',
}
</script>
