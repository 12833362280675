<template>
  <ul class="vHistoryList">
    <li
      v-for="(item, index) in items"
      :key="index"
      class="vHistoryList__item"
    >
      <div
        v-if="!watchPeriod && filter === ''"
        class="vHistoryList__info"
      >
        <div class="vHistoryList__date">
          {{ item.day }}
        </div>
        <div class="vHistoryList__container-el">
          <div class="vHistoryList__el">
            <vIconRuble />
            Итого:
            <span>{{ parseInt(item.sum) / 100 }} &#8381;</span>
          </div>
          <div class="vHistoryList__el">
            <vIconTip />
            Чаевые:
            <span>{{ parseInt(item.tips) / 100 }} &#8381;</span>
          </div>
        </div>
      </div>
      <div>
        <vHistoryRows
          :items="item.items"
          @toggleConfirmModal="toggleConfirmModal"
        />
      </div>
    </li>
  </ul>
</template>

<script>
import vIconRuble from '@/assets/images/icons/v-icon-ruble'
import vIconTip from '@/assets/images/icons/v-icon-tip'
import vHistoryRows from '@/components/history/v-history-rows'
export default {
  name: 'VHistoryList',
  components: {
    vIconRuble,
    vIconTip,
    vHistoryRows,
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    watchPeriod: {
      type: Boolean,
      required: true,
      default: false,
    },
    filter: {
      type: String,
      required: true,
      default: '',
    },
  },
  emits: ['toggleConfirmModal', 'onWatchPeriod', 'resetValues'],
  data() {
    return {
      copyItems: [],
    }
  },
  mounted() {
    const propItems = this.items.map((i) => {
      const dayArray = i.day.split('-')
      return { ...i, day: `${dayArray[2]}.${dayArray[1]}.${dayArray[0]}` }
    })
    this.copyItems = [...propItems]
  },

  methods: {
    toggleConfirmModal(id) {
      this.$emit('toggleConfirmModal', id)
    },
  },
}
</script>
<style lang="scss">
.vHistoryList {
  margin: 0;

  &__item {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    flex-wrap: wrap;
  }

  &__date {
    color: #010d35;
    font-weight: 600;
    font-size: 20px;
    margin-right: 12px;

    @media (max-width: 769px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  &__container-el {
    display: flex;
    align-items: center;

    @media (max-width: 440px) {
      flex-direction: column;
    }
  }

  &__el {
    border: 1px solid #e6e7eb;
    border-radius: 6px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    padding: 2px 6px;
    font-size: 15px;
    color: #343d5d;
    font-weight: 400;

    @media (max-width: 769px) {
      font-size: 13px;
      width: calc(50% - 4px);
      margin-right: 4px;
      justify-content: center;
      //&:last-child {
      //  margin-left: 4px;
      //  margin-right: 0;
      //}
    }

    @media (max-width: 440px) {
      width: 100%;
      margin-bottom: 16px;
      justify-content: flex-start;
    }

    svg {
      margin-right: 7px;
    }

    span {
      font-weight: 500;
      padding-left: 5px;
      white-space: nowrap;
    }
  }
}
</style>
