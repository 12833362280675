<template>
  <ul class="vHistoryRows">
    <li
      v-for="item in items"
      :key="item.transaction_id"
      class="vHistoryRows__item"
    >
      <div class="vHistoryRows__col">
        <div class="vHistoryRows__wrap">
          <span>{{ item.date }}</span>
          {{ item.time }}
        </div>
      </div>
      <div class="vHistoryRows__col">
        <div class="vHistoryRows__wrap">
          <span>Официант</span>
          <p>
            {{ item.info && item.info.waiter ? item.info.waiter.name : '' }}
          </p>
        </div>
      </div>
      <div class="vHistoryRows__col">
        <div class="vHistoryRows__wrap">
          <span>Стол</span>
          <p>{{ item.info ? item.info.table_code : '' }}</p>
        </div>
      </div>
      <div
        class="vHistoryRows__col vHistoryRows__col--split"
        :class="{ red: item.tip_status && item.tip_status === 'Rejected' }"
      >
        <div class="vHistoryRows__wrap">
          <span>Чаевые</span>
          {{ parseInt(item.tips) / 100 }} &#8381;
        </div>
      </div>
      <div
        class="vHistoryRows__col"
        :class="
          (USER_DATA.role_user !== 'support' &&
            item.info &&
            item.info.front_status &&
            item.info.front_status) ||
            (item.info && item.info.status && item.info.status.toUpperCase())
        "
      >
        <div
          v-if="USER_DATA.role_user === 'support'"
          class="vHistoryRows__wrap"
        >
          <span>Статус</span>
          <p>{{ item.info && item.info.status && item.info.status }}</p>
        </div>

        <div
          v-else
          class="vHistoryRows__wrap"
        >
          <span>Статус</span>
          <p>{{ item.info && item.info.front_status }}</p>
        </div>
      </div>
      <div
        class="vHistoryRows__col"
        :class="item.info && item.info.status === 'COMPLETED' ? 'green' : 'red'"
      >
        <div class="vHistoryRows__wrap">
          <span>Сумма за заказ</span>
          {{ parseInt(item.sum) / 100 }} &#8381;
        </div>
      </div>

      <div
        v-if="false"
        class="vHistoryRows__btn-wrap"
      >
        <template v-if="USER_DATA.role_user === 'support'">
          <button
            class="vHistoryRows__btn"
            type="button"
            :disabled="item.info.type !== 'COMPLETE'"
            @click="safePayment(item.info.transaction_id)"
          >
            <vIconCheck v-if="false" />
            Перевести в заведение
          </button>
          <button
            class="vHistoryRows__btn"
            :disabled="true"
            @click="safeCloseDeal(item.transaction_id)"
          >
            Перевести физ. лицу
          </button>
        </template>
        <template v-else>
          <button
            v-if="
              (item.info && item.info.status === 'CANCELED') ||
                (item.info && item.info.front_status === 'Reverse')
            "
            :disabled="true"
            class="vHistoryRows__btn disabled"
          >
            <vIconCheck />
            Возврат оформлен
          </button>
          <button
            v-else
            class="vHistoryRows__btn"
            :disabled="
              parseInt(item.tips) !== 0 ||
                !item.allow_refund ||
                item.info.status === 'REGISTERED'
            "
            @click="toggleConfirmModal(item.info.order_id, item.allow_refund)"
          >
            Оформить возврат
          </button>
          <!-- <div
            class="vHistoryRows__btn disabled"
          >
            <vIconCheck />
            Возврат оформлен
          </div> -->
        </template>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vIconCheck from '@/assets/images/icons/v-icon-check'
export default {
  name: 'VHistoryRows',
  components: {
    vIconCheck,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  emits: ['toggleConfirmModal'],
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED', 'USER_DATA']),
  },
  methods: {
    ...mapActions('transactions', ['SAFE_PAYMENT', 'SAFE_CLOSE_DEAL']),
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),

    toggleConfirmModal(id, success) {
      if (success) {
        this.$emit('toggleConfirmModal', id)
      }
    },
    async safeCloseDeal(id) {
      this.TOGGLE_PRELOADER(true)
      await this.SAFE_CLOSE_DEAL({ token: this.GET_AUTHENTIFICATED, id: id })
      setTimeout(() => {
        this.TOGGLE_PRELOADER(false)
      }, 500)
    },
    async safePayment(id) {
      this.TOGGLE_PRELOADER(true)
      await this.SAFE_PAYMENT({ token: this.GET_AUTHENTIFICATED, id: id })
      setTimeout(() => {
        this.TOGGLE_PRELOADER(false)
      }, 500)
    },
  },
}
</script>
<style lang="scss">
.vHistoryRows {
  margin-bottom: 16px;
  &__btn-wrap {
    display: flex;
    flex-direction: column;
    .vHistoryRows__btn:not(:first-child) {
      margin-top: 13px;
    }
  }
  &__item {
    border: 1px solid #e6e7eb;
    border-radius: 11px;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    @media (max-width: 1360px) {
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0;
      border: none;
      border-bottom: 1px solid #e6e7eb;
      padding-bottom: 12px;
      border-radius: 0;
      align-items: flex-start;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__el {
    display: inline-flex;
    align-items: center;
  }
  &__wrap {
    display: inline-flex;
    flex-direction: column;
    p {
      margin-bottom: 0;
      min-height: 20px;
    }
    @media (max-width: 1360px) {
      min-width: 105px;
    }
  }
  &__col {
    margin-right: 46px;
    color: #343d5d;
    font-size: 16px;
    line-height: 20px;
    display: inline-flex;
    font-weight: 500;
    min-width: 100px;
    @media screen and (min-width: 1360px) {
      margin-right: 60px;
    }
    &:nth-child(1) {
      @media screen and (min-width: 1360px) {
        margin-right: 40px;
      }
    }
    &:nth-child(2) {
      width: 100%;
      max-width: 150px;
      @media screen and (min-width: 1360px) {
        margin-right: 20px;
      }
    }
    &:nth-child(4) {
      // margin-left: auto;
      @media (max-width: 1360px) {
        margin-left: 0;
      }
    }
    &:nth-child(5) {
      min-width: 88px;
      @media screen and (min-width: 1360px) {
        margin-right: 50px;
      }
    }
    &:nth-child(6) {
      @media screen and (min-width: 1360px) {
        margin-right: 20px;
      }
    }
    @media (max-width: 1360px) {
      width: 128px;
    }
    @media (max-width: 1360px) {
      font-size: 13px;
      line-height: 18px;
      width: 33.33%;
      margin-right: 0;
      margin-bottom: 16px;
      &:nth-child(2),
      &:nth-child(5) {
        justify-content: center;
      }
      &:nth-child(3),
      &:nth-child(6) {
        justify-content: flex-end;
      }
      &:nth-child(2),
      &:nth-child(5) {
        width: 33.33%;
        min-width: auto;
      }
      &:nth-child(3) ~ .vHistoryRows__col {
        margin-bottom: 0;
      }
    }
    &--split {
      // margin-left: auto;
      @media (max-width: 1360px) {
        margin-left: 0;
      }
    }
    &.AUTHORIZE,
    &.COMPLETE {
      color: #000000;
    }
    &.PURCHASE,
    &.P2PTRANSFER,
    &.SUCCESS {
      color: #4cd964;
    }
    &.COMPLETED {
      color: #d4751d;
    }
    &.REJECT {
      color: #ec4e4e;
    }
    &.Success,
    &.Success + * {
      color: #25c26e;
    }
    &.Reject,
    &.Reject + * {
      color: #ec4e4e;
    }
    // &.red {
    //   color: #ec4e4e;
    // }
    span {
      color: #80869a;
      font-weight: 400;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &__btn-wrap {
    @media (max-width: 1360px) {
      width: 100%;
    }
  }
  &__btn {
    width: 208px;
    display: inline-flex;
    justify-self: center;
    align-items: center;
    background: #f7f8fa;
    border-radius: 6px;
    font-weight: 600;
    font-size: 15px;
    color: #80869a;
    transition: all 0.3s;
    height: 36px;
    justify-content: center;
    cursor: pointer;
    background: #edeef1;
    color: #343d5d;
    border: none;
    @media (max-width: 1360px) {
      width: 100%;
    }
    svg {
      margin-right: 6px;
    }
    &:hover {
      background: #edeef1;
      color: #343d5d;
    }
    &:disabled,
    &:disabled:hover {
      background: #f7f8fa;
      color: #80869a;
      cursor: no-drop;
    }
  }
}
.vHistoryRows__col--split.red {
  color: #ec4e4e;
}
</style>
