<template>
  <div
    class="vHistoryModal"
    @click="toggleConfirmModal"
  >
    <div
      class="vHistoryModal__content"
      @click.stop
    >
      <div
        class="vHistoryModal__close"
        @click="toggleConfirmModal"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 8L8 16M8 8L16 16L8 8Z"
            stroke="#EC4E4E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="vHistoryModal__title">
        Предупреждение
      </div>
      <div class="vHistoryModal__description">
        Вы точно хотите оформить возврат средств?
      </div>
      <div class="vHistoryModal__buttons">
        <div class="vHistoryModal__button">
          <vButton
            theme="gray"
            @click="toggleConfirmModal"
          >
            Отменить
          </vButton>
        </div>
        <div class="vHistoryModal__button">
          <vButton @click="reverseTransaction">
            Возврат
          </vButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vButton from '@/components/v-button'
export default {
  name: 'VHistoryModal',
  components: {
    vButton,
  },
  emits: ['toggleConfirmModal', 'reverseTransaction'],
  methods: {
    toggleConfirmModal() {
      this.$emit('toggleConfirmModal')
    },
    reverseTransaction() {
      this.$emit('reverseTransaction')
    },
  },
}
</script>
<style lang="scss">
.vHistoryModal {
  background: rgba(220, 222, 227, 0.9);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  &__content {
    background: #fff;
    border-radius: 12px;
    position: relative;
    padding: 20px 16px;
    width: 540px;
    @media (max-width: 540px) {
      width: calc(100% - 40px);
    }
  }
  &__title {
    color: #010d35;
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 24px;
  }
  &__close {
    border: 1px solid #edeef1;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    top: 20px;
    right: 16px;
    background: #f7f8fa;
    width: 24px;
    height: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &__description {
    font-size: 17px;
    text-align: center;
    margin-bottom: 55px;
  }
  &__buttons {
    display: flex;
    & > * {
      width: calc(100% - 8px);
    }
    & > *:first-child {
      margin-right: 4px;
    }
    & > *:last-child {
      margin-left: 4px;
    }
  }
}
</style>
