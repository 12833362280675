<template>
  <div class="vHistoryTotalPeriod">
    <div
      v-if="periodValue !== ''"
      class="vHistoryTotalPeriod__date"
    >
      {{ periodValue }}
    </div>
    <div
      v-if="periodValue === ''"
      class="vHistoryTotalPeriod__date"
    >
      {{ filterDateStart }} - {{ filterDateEnd }}
    </div>
    <div class="vHistoryTotalPeriod__container-el">
      <div class="vHistoryTotalPeriod__el">
        <vIconRuble />
        Итого:
        <span>{{ calculatingTotalAmount / 100 }} &#8381;</span>
      </div>
      <div class="vHistoryTotalPeriod__el">
        <vIconTip />
        Чаевые:
        <span>{{ calculatingFinalTips / 100 }} &#8381;</span>
      </div>
    </div>
    <div />
  </div>
</template>

<script>
import vIconRuble from '@/assets/images/icons/v-icon-ruble'
import vIconTip from '@/assets/images/icons/v-icon-tip'

export default {
  name: 'VHistoryTotalsPeriod',
  components: {
    vIconRuble,
    vIconTip,
  },
  props: {
    calculatingTotalAmount: {
      type: Number,
      required: false,
      default: 0,
    },
    calculatingFinalTips: {
      type: Number,
      required: false,
      default: 0,
    },
    periodValue: {
      type: String,
      default: '',
    },
    filterDateStart: {
      type: String,
      default: '',
    },
    filterDateEnd: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },

  methods: {},
}
</script>
<style lang="scss">
.vHistoryTotalPeriod {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  @media (max-width: 570px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__container-el {
    display: flex;
    align-items: center;

    @media (max-width: 420px) {
      flex-direction: column;
    }

    @media (max-width: 370px) {
      flex-direction: column;
    }
  }

  &__el {
    border: 1px solid #e6e7eb;
    border-radius: 6px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    padding: 2px 6px;
    font-size: 15px;
    color: #343d5d;
    font-weight: 400;

    @media (max-width: 769px) {
      font-size: 13px;
      width: calc(50% - 4px);
      margin-right: 4px;
      justify-content: center;
      //&:last-child {
      //  margin-left: 4px;
      //  margin-right: 0;
      //}
    }

    @media (max-width: 420px) {
      width: 100%;
      margin-bottom: 16px;
      justify-content: flex-start;
    }

    @media (max-width: 370px) {
      width: 100%;
      margin: 0;

      &:last-child {
        margin-top: 10px;
      }
    }

    svg {
      margin-right: 7px;
    }

    span {
      font-weight: 500;
      padding-left: 5px;
      white-space: nowrap;
    }
  }

  &__date {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    margin-right: 12px;

    @media (max-width: 570px) {
      margin-bottom: 16px;
    }
  }
}
</style>
