<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 21C7.529 21 3.5 16.971 3.5 12C3.5 7.029 7.529 3 12.5 3C17.471 3 21.5 7.029 21.5 12C21.5 16.971 17.471 21 12.5 21Z"
      stroke="#80869A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.5 10L11.5 15L8.5 12"
      stroke="#80869A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconCheck',
}
</script>
